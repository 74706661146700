import React from "react";
import { Link } from "react-router-dom";

const ProductInfo = ({ productInfo }) => {

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">R${productInfo.price}</p>
      <p className="text-base text-gray-600">{productInfo.des}</p>
      <p className="font-medium text-lg">
        <span className="font-normal">Colors:</span> {productInfo.color}
      </p>
      

      
      {/* Link para o WhatsApp */}
      <Link
        to="https://api.whatsapp.com/send/?phone=5519992443766&text=Falar+com+a+Carbon+Steel&type=phone_number&app_absent=0"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont text-center mt-3"
      >
        Entrar em Contato
      </Link>
    </div>
  );
};

export default ProductInfo;
