import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";


// Dados de exemplo
const collectionsData = [
  {
    year: "2024",
    description: "Elegância ao estilo industrial.",
    price: "R$ 15.000,00",
    items: [
      { name: "Mesa Industrial 2024", img: "mesa2024.jpg", price: "R$ 5.000,00" },
      { name: "Cadeira Industrial 2024", img: "cadeira2024.jpg", price: "R$ 2.000,00" },
    ],
  },
  {
    year: "2023",
    description: "Funcionalidade moderna com um toque industrial.",
    price: "R$ 13.000,00",
    items: [
      { name: "Mesa Industrial 2023", img: "mesa2023.jpg", price: "R$ 4.500,00" },
      { name: "Cadeira Industrial 2023", img: "cadeira2023.jpg", price: "R$ 1.800,00" },
    ],
  },
];

const CollectionDetails = () => {
  const { year } = useParams();  // Captura o parâmetro da URL
  const collection = collectionsData.find((col) => col.year === year);

  if (!collection) {
    return <div className="text-center">Coleção não encontrada.</div>;
  }

  return (
    <div className="max-w-container mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-bold mb-8">Coleção {collection.year}</h1>
      <p className="mb-4">{collection.description}</p>
      <p className="text-xl font-semibold mb-6">Preço total: {collection.price}</p>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {collection.items.map((item, idx) => (
          <div key={idx} className="border p-4 rounded-lg">
            <img
              src={`/images/${item.img}`} // Ajuste o caminho da imagem
              alt={item.name}
              className="mb-4"
            />
            <h2 className="text-lg font-bold">{item.name}</h2>
            <p className="text-sm">Preço: {item.price}</p>
           
          </div>
        ))}
      </div>
      <br></br>
      <br></br>

      <Link
  to="https://api.whatsapp.com/send/?phone=5519992443766&text=Falar+com+a+Carbon+Steel&type=phone_number&app_absent=0"
  target="_blank"
  rel="noopener noreferrer"
  className="w-full py-4 px-6 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont text-center mt-3"
>
  Entrar em Contato
</Link>

    </div>
  );
};

export default CollectionDetails;
