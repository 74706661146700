
import React from "react";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import "./product.css";

const Product = (props) => {
  const _id = props.productName;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);

  const navigate = useNavigate();
  const productItem = props;

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };

  return (
    <div className="product-card w-full relative group" onClick={handleProductDetails}>
      <div className="relative overflow-hidden">
        {/* Clique na imagem também direciona para os detalhes do produto */}
        <Image className="product-image cursor-pointer" imgSrc={props.img} />
        <div className="absolute top-6 left-8">
          {props.badge && <Badge text="Novidade" />}
        </div>
      </div>
      <div className="py-6 flex flex-col gap-1 border-t-0 px-4">
        {/* Clique nos detalhes também direciona para a página do produto */}
        <div className="flex items-center justify-between font-titleFont cursor-pointer">
          <h2 className="text-lg text-primeColor font-bold">
            {props.productName}
          </h2>
          <p className="text-[#767676] text-[14px]">R${props.price}</p>
        </div>
        <div>
          <p className="text-[#767676] text-[14px]">{props.color}</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
