import React from "react";
import { Link } from "react-router-dom";
import notFoundImage from "../assets/images/icons/9.png"; // Importe a imagem aqui

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center text-center">
      {/* Imagem de página não encontrada */}
      <img 
        src={notFoundImage} 
        alt="Página não encontrada" 
        className="w-64 h-64 mb-8" // Ajuste o tamanho da imagem
      />
      
      <h1 className="text-5xl font-bold mb-4">404</h1>
      <p className="text-xl mb-8">Ops! A página que você está procurando não foi encontrada.</p>
      <Link to="/" className="px-4 py-2 bg-black text-white rounded-full">
        Voltar para a página inicial
      </Link>
    </div>
  );
};

export default NotFound;
