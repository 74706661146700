import React from "react";
import { Link } from "react-router-dom";
import "./col.css";
import cbsCol from "./cbs_col.jpeg";
import Banner from "./Banner.jpeg";
import cbs from "../../../assets/images/vpl/CBS.png";

const collectionsData = [
  {
    year: "2024",
    items: [
      { name: "Carbon Steel para Ambientes de Trabalho", img: cbsCol },
    ],
  },
  {
    year: "2023",
    items: [
      { name: "Renove Ambiente", img: Banner },
    ],
  },
  {
    year: "2022",
    items: [
      { name: "Banner 2022", img: cbs },
    ],
  },
];

const Collections = () => {
  return (
    <div className=" mx-auto py-16 px-4 sm:px-6 lg:px-8 ">
      <h1 className="text-4xl font-extrabold text-center text-gray-900 mb-12">
        Coleções de Móveis por Ano
      </h1>

      {collectionsData.map((collection, index) => (
        <div key={index} className="mb-16">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">{collection.year}</h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"> 
            {collection.items.map((item, idx) => (
              <div
              
              key={idx}
              className="relative border rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-shadow duration-300"
            >
              <img
  src={item.img}
  alt={item.name}
  className="w-full h-56 object-cover"
/>
              <div className="p-4 bg-white">
                <h3 className="text-lg font-bold text-gray-900 mb-2">{item.name}</h3>
              </div>
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <Link to={`/collections/${collection.year}`}>
                  <button className="px-4 py-2 text-white bg-black rounded-full">
                    Ver Detalhes
                  </button>
                </Link>
              </div>
            </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Collections;
