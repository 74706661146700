import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom"; 
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Developer from "./pages/VPLstudios/Developer";
import Collections from "./pages/VPLstudios/col/Collections";
import CollectionDetails from "./pages/VPLstudios/col/CollectionDetails";
import Home from "./pages/Home/Home";
import Offer from "./pages/Offer/Offer";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import NotFound from "./pages/NotFound"; // Página 404 personalizada

// Layout para Header, Footer e Outlet
const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};

// Definir as rotas
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/about" element={<About />} />
      <Route path="/vplstudios" element={<Developer />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/collections/:year" element={<CollectionDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/offer" element={<Offer />} />
      <Route path="/product/:_id" element={<ProductDetails />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

// Aplicação principal
function App() {
  useEffect(() => {
    if (window.location.protocol !== "https:") {
      window.location.href = "https://" + window.location.hostname + window.location.pathname;
    }
  }, []);
  
  return (
    <div className="font-bodyFont">

      <RouterProvider router={router} />
    </div>
  );
}

export default App;
