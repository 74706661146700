import React from "react";
import { Link } from "react-router-dom";
import "./tittle.css";

const About = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      {/* Título principal */}
      <h1 className="about-title">Sobre Nós</h1>
      
      <div className="pb-10 about-text">
        <p>
          Fundada em 2020, a Carbon Steel é uma empresa especializada na fabricação de Móveis Industriais,
          comprometida em oferecer soluções inovadoras e duradouras. Nosso objetivo principal é garantir
          produtos de alta qualidade, que aliem robustez, funcionalidade e design, atendendo às demandas dos clientes mais exigentes.
        </p>

        <p>
          Com um time de profissionais qualificados e ampla experiência no mercado, investimos constantemente
          em tecnologia de ponta e materiais de alta resistência, para entregar produtos que superem as expectativas
          em termos de durabilidade e desempenho.
        </p>

        <p>
          Na Carbon Steel, acreditamos que a excelência está nos detalhes. Por isso, estamos sempre atentos às tendências
          e às necessidades dos nossos clientes, proporcionando soluções personalizadas que contribuem para um ambiente
          de trabalho mais eficiente e seguro.
        </p>

        <p>
          Nosso compromisso vai além de produtos robustos: buscamos oferecer um atendimento de qualidade, com foco
          em parcerias de longo prazo. Cada projeto é tratado com cuidado e atenção, garantindo a satisfação e a confiança
          de nossos clientes.
        </p>

        <p className="about-signature">
          Carbon Steel - Móveis Industriais
        </p>

        <Link to="/shop">
          <button className="btn-primary">Voltar para o Início</button>
        </Link>
      </div>
    </div>
  );
};

export default About;
