import React, { useState, useEffect } from "react";
import "./developer.css"; // Estilos para a página do desenvolvedor
import proj1 from "../../assets/images/vpl/VPL.png";
import proj2 from "../../assets/images/vpl/VPL.png";
import proj3 from "../../assets/images/vpl/VPL.png";
import client from "../../assets/images/vpl/VPL.png";
import client2 from "../../assets/images/vpl/VPL.png";
import client3 from "../../assets/images/vpl/VPL.png";
import face from "../../assets/images/vpl/face.svg";
import insta from "../../assets/images/vpl/insta.svg";
import github from "../../assets/images/vpl/github.svg";
import linkedin from "../../assets/images/vpl/lin.svg";
import logo from "../../assets/images/vpl/asset.png";

const projects = [
  {
    title: "Projeto 1 - Plataforma E-commerce",
    description: "Desenvolvimento de uma plataforma robusta para comércio eletrônico com integração de pagamentos e UX otimizado.",
    image: proj1,  // Remover as chaves {} ao redor da imagem
  },
  {
    title: "Projeto 2 - Aplicativo Móvel",
    description: "Aplicativo móvel para gestão de equipes, com funcionalidades de chat, calendários e integração com plataformas de trabalho.",
    image: proj2,  // Remover as chaves {} ao redor da imagem
  },
  {
    title: "Projeto 3 - Sistema de ERP",
    description: "Sistema de gestão empresarial totalmente customizado para atender às necessidades de pequenas e médias empresas.",
    image: proj3,  // Remover as chaves {} ao redor da imagem
  },
];

const testimonials = [
  {
    client: "João Silva",
    feedback: "A equipe da empresa desenvolvedora superou todas as expectativas. O projeto foi entregue no prazo e com uma qualidade incrível!",
    image: client,  // Remover as chaves {} ao redor da imagem
  },
  {
    client: "Maria Souza",
    feedback: "Estamos extremamente satisfeitos com o trabalho da equipe de desenvolvimento. O aplicativo tem sido um sucesso com nossos usuários!",
    image: client2,  // Remover as chaves {} ao redor da imagem
  },
  {
    client: "Carlos Pereira",
    feedback: "Excelente comunicação e suporte durante todo o processo. Com certeza voltaremos a trabalhar juntos em novos projetos.",
    image: client3,  // Remover as chaves {} ao redor da imagem
  },
];

const Developer = () => {
  const [currentProject, setCurrentProject] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProject((prevProject) => (prevProject + 1) % projects.length);
    }, 5000); // Alterar a cada 5 segundos
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="max-w-container mx-auto px-4">
      {/* Seção de Introdução */}
      <section className="developer-intro py-10 text-center">
        <img src={logo} alt="Logo da Empresa Desenvolvedora" className="mx-auto mb-6 w-32 h-32" />
        <h1 className="font-titleFont font-semibold text-4xl">Empresa Desenvolvedora</h1>
        <p className="mt-4 text-lg max-w-3xl mx-auto">
          Somos uma empresa focada em oferecer soluções digitais inovadoras. Especializados no desenvolvimento de
          sites e aplicativos, nossos projetos são desenhados com foco em performance, usabilidade e modernidade. 
        </p>
      </section>

      {/* Seção de Projetos (Carrossel) */}
      <section className="developer-projects py-10">
        <h2 className="font-titleFont font-semibold text-3xl text-center">Projetos Recentes</h2>
        <div className="project-carousel mt-8">
          <div className="project-card">
            <img src={projects[currentProject].image} alt={projects[currentProject].title} className="w-full h-64 object-cover rounded-md" />
            <h3 className="font-titleFont font-semibold text-xl mt-4">{projects[currentProject].title}</h3>
            <p className="mt-2">{projects[currentProject].description}</p>
          </div>
        </div>
      </section>

      {/* Seção de Depoimentos */}
      <section className="developer-testimonials py-10 bg-gray-100">
        <h2 className="font-titleFont font-semibold text-3xl text-center">Depoimentos de Clientes</h2>
        <div className="testimonials-grid mt-8 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <img src={testimonial.image} alt={testimonial.client} className="w-20 h-20 rounded-full mx-auto mb-4" />
              <blockquote className="italic text-lg text-center">"{testimonial.feedback}"</blockquote>
              <p className="text-right mt-4 font-semibold">- {testimonial.client}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Seção de Serviços */}
      <section className="developer-services py-10">
        <h2 className="font-titleFont font-semibold text-3xl text-center">Nossos Serviços</h2>
        <div className="services-list mt-8 text-lg max-w-3xl mx-auto text-center">
          <ul>
            <li>Desenvolvimento Web</li>
            <li>Desenvolvimento de Aplicativos Móveis</li>
            <li>Design UX/UI</li>
            <li>Consultoria de TI</li>
            <li>Suporte e Manutenção</li>
          </ul>
        </div>
      </section>

      {/* Seção de Redes Sociais */}
      <section className="developer-socials py-10">
        <h2 className="font-titleFont font-semibold text-3xl text-center">Siga-nos nas Redes Sociais</h2>
        <div className="flex justify-center gap-4 mt-8">
          <a href="https://www.facebook.com/developer" target="_blank" rel="noreferrer">
            <img src={face} alt="Facebook" className="w-8 h-8" />
          </a>
          <a href="https://www.instagram.com/developer" target="_blank" rel="noreferrer">
            <img src={insta} alt="Instagram" className="w-8 h-8" />
          </a>
          <a href="https://www.linkedin.com/company/developer" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="LinkedIn" className="w-8 h-8" />
          </a>
          <a href="https://www.github.com/developer" target="_blank" rel="noreferrer">
            <img src={github} alt="GitHub" className="w-8 h-8" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default Developer;
