import React from "react";

import "./contact.css";
import face from "../../assets/images/icons/face.svg";
import instagram from "../../assets/images/icons/insta.svg";
import linke from "../../assets/images/icons/lin.svg";


const Contact = () => {


  return (
    <div className="max-w-container mx-auto px-4">

      <div className="pb-20">
        {/* Seção de Contato Principal */}
        <h1 className="font-titleFont font-semibold text-3xl text-center">Entre em Contato Conosco</h1>

        {/* Informações de Contato */}
        <div className="contact-info py-10">
          <h2 className="font-titleFont font-semibold text-2xl">Informações de Contato</h2>

          <p className="text-lg mt-4">
            <strong>Email:</strong> <a href="mailto:contato@carbonsteel.store" className="text-primeColor">contato@carbonsteel.store</a>
          </p>
          <p className="text-lg">
            <strong>WhatsApp:</strong> <a href="https://wa.me/5519992443766" className="text-primeColor" target="_blank" rel="noreferrer">+55 (19) 99244-3766</a>
          </p>
          <p className="text-lg">
            <strong>Chave Pix:</strong> <span>financeiro@carbonsteel.store</span>
          </p>

          {/* Redes Sociais */}
          <div className="social-links mt-8">
            <h2 className="font-titleFont font-semibold text-2xl">Redes Sociais</h2>
            <div className="flex gap-4 mt-4">
              <a href="https://www.facebook.com/carbonsteel" target="_blank" rel="noreferrer">
                <img src={face} alt="Facebook" className="w-8 h-8" />
              </a>
              <a href="https://www.instagram.com/carbonsteeldesigns/" target="_blank" rel="noreferrer">
                <img src={instagram} alt="Instagram" className="w-8 h-8" />
              </a>
              <a href="https://www.linkedin.com/company/carbonsteel" target="_blank" rel="noreferrer">
                <img src={linke} alt="LinkedIn" className="w-8 h-8" />
              </a>
            </div>
          </div>
        </div>

        {/* Endereço da Empresa */}
        <div className="company-address py-10">
          <h2 className="font-titleFont font-semibold text-2xl">Nosso Endereço</h2>
          <p className="text-lg mt-4">
            Rua dos Exemplos, 123<br />
            São Paulo, SP - Brasil<br />
            CEP 01234-567
          </p>
        </div>

        {/* Mapa Integrado */}
        <div className="map-container py-10">
          <h2 className="font-titleFont font-semibold text-2xl">Localização no Mapa</h2>
          <iframe
            className="mt-4 w-full h-80"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.8642145624477!2d100.53590691539288!3d13.724794801103985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ed0f13de9a1%3A0x89dbd8e1de6e55a4!2sSiam%20Paragon!5e0!3m2!1sen!2sth!4v1637742011541!5m2!1sen!2sth"
            allowFullScreen=""
            loading="lazy"
            title="Mapa"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
